.follow-buy-person .tips {
    height: 34px;
    /* line-height: 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #faac25;
    color: #fff;
    font-size: 14px;
}
.follow-buy-person .tips .uni-image {
    width: 15px;
    height: 15px;
    margin-right: 8px;
}
.follow-buy-person .myInfo {
    background-color: #fff;
}
.follow-buy-person .myInfo .t {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
}
.follow-buy-person .myInfo .t .left {
    display: flex;
    align-items: center;
}
.follow-buy-person .myInfo .t .left .uni-image {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
}
.follow-buy-person .myInfo .t .left .t1 {
    margin-left: 8px;
}
.follow-buy-person .myInfo .t .right {
    border: 1px solid #fc4b27;
    padding: 2px 6px;
    border-radius: 5px;
}
.follow-buy-person .myInfo .t .right span {
    color: #fc4b27;
}
.follow-buy-person .myInfo .c {
    font-size: 12px;
    padding: 9px;
    display: flex;
    justify-content: space-around;
    text-align: center;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
}

.follow-buy-person .myInfo .c .c1 span {
    color: #fc4b27;
}

.follow-buy-person .myInfo .c .c1 div {
    font-size: 10px;
    color: #999;
}

.follow-buy-person .myInfo .f {
    padding: 12px 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}
.follow-buy-person .myInfo .f .fbox {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.follow-buy-person .myInfo .f .fbox .b1 {
    position: absolute;
    left: 0;
    top: calc(50% - 1px);
    height: 3px;
    background: #fc4b27;
    content: "";
    width: 100%;
    display: block;
}
.follow-buy-person .myInfo .f .fbox .f2 {
    z-index: 3;
    text-align: center;
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    background-color: #999;
}
.follow-buy-person .myInfo .f .fbox .f2.active {
    z-index: 3;
    color: #fff;
    background-color: #fc4b27;
}

.follow-buy-person .container {
    margin-top: 8px;
    margin-bottom: 5vh;
}

.follow-buy-person .container .item {
    padding: 8px 8px 1px;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
}
.follow-buy-person .container .item .ct {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.follow-buy-person .container .item .ct .left {
    display: flex;
    font-size: 12px;
    align-items: center;
}
.follow-buy-person .container .item .ct .left .uni-image {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    overflow: hidden;
}
.follow-buy-person .container .item .ct .right {
    color: #999;
}
.follow-buy-person .container .item .cc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 11px;
}
.follow-buy-person .container .item .cc .left .l1 {
    margin-top: 8px;
}
.follow-buy-person .container .item .cc .left span {
    color: #fc4b27;
}
.follow-buy-person .container .item .cc .left .l2 {
    margin: 4px 0 8px;
}
.follow-buy-person .container .item .cc .right .hall-btn {
    color: #fc4b27;
    padding: 3px 10px;
    border: 1px solid #fc4b27;
    border-radius: 10px;
}
.follow-buy-person .container .item .cc .right .win {
    position: relative;
    width: 64px;
    height: 56px;
    border-radius: 50%;
}
.follow-buy-person .container .item .cc .right .win .money {
    position: absolute;
    font-size: 8px;
    color: #fc4b27;
    left: 0;
    top: 40%;
    width: 100%;
    text-align: center;
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
}
.follow-buy-person .container .item .cc .right .unwin {
    width: 64px;
    height: 56px;
    border-radius: 50%;
}