
.follow-buy-me.bigView {
    width: 100vw;
    min-height: 100vh;
    padding-bottom: 80px
}

.follow-buy-me .noData {
    text-align: center;
    padding: 28px 0
}

.follow-buy-me .noData .box {
    display: flex;
    flex-direction: column;
    align-items: center
}

.follow-buy-me .noData .box .uni-image {
    width: 40px;
    height: 40px;
    margin-bottom: 11px
}

.follow-buy-me .noData .box .n1 {
    margin-top: 11px;
    width: 90px;
    height: 34px;
    line-height: 34px;
    border: 1px solid #fc4b27;
    color: #fc4b27;
    text-align: center;
    border-radius: 8px;
    display: inline-block
}

.follow-buy-me .container {
    margin-top: 11px;
    background-color: #fff
}

.follow-buy-me .container .content3 .item3 {
    padding: 11px 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f1f1f1
}

.follow-buy-me .container .content3 .item3 .left {
    display: flex;
    align-items: center
}

.follow-buy-me .container .content3 .item3 .left .uni-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.follow-buy-me .container .content3 .item3 .left span {
    margin-left: 11px
}

.follow-buy-me .container .content3 .item3 .right {
    display: flex;
    align-items: center
}

.follow-buy-me .container .content3 .item3 .right .r1 {
    margin-right: 11px
}

.follow-buy-me .container .content .item {
    padding: 17px 23px;
    border-bottom: 1px solid #e5e5e5
}

.follow-buy-me .container .content .item .top1 {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.follow-buy-me .container .content .item .top1 span {
    margin-right: 11px
}

.follow-buy-me .container .content .item .ttop {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.follow-buy-me .container .content .item .ttop .left {
    display: flex;
    align-items: center
}

.follow-buy-me .container .content .item .ttop .left .uni-image {
    width: 47px;
    height: 47px;
    border-radius: 50%;
    overflow: hidden;
}

.follow-buy-me .container .content .item .ttop .left span {
    margin-left: 11px
}

.follow-buy-me .container .content .item .footer {
    margin-top: 11px;
    display: flex;
    justify-content: space-between
}

.follow-buy-me .container .content .item .footer .left span {
    margin-right: 11px
}

.follow-buy-me .container .content .item .footer .right span {
    margin-right: 11px
}

.follow-buy-me .myInfo {
    background-color: #fff
}

.follow-buy-me .myInfo .f {
    padding: 11px;
    display: flex;
    justify-content: space-around;
    align-items: center
}

.follow-buy-me .myInfo .f span {
    width: 15%
}

.follow-buy-me .myInfo .f .f2 {
    text-align: center;
    color: #fff;
    width: 27px;
    height: 27px;
    line-height: 27px;
    border-radius: 50%;
    background-color: #999
}

.follow-buy-me .myInfo .f .f2.active {
    color: #fff;
    background-color: #fc4b27
}

.follow-buy-me .myInfo .c {
    padding: 11px;
    display: flex;
    justify-content: space-around;
    text-align: center;
    border-bottom: 1px solid #f5f5f5
}

.follow-buy-me .myInfo .c .c1 span {
    color: #fc4b27
}

.follow-buy-me .myInfo .c .c1 uni-view {
    font-size: 14px;
    color: #999
}

.follow-buy-me .myInfo .t {
    padding: 11px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f5f5f5
}

.follow-buy-me .myInfo .t .uni-image {
    width: 47px;
    height: 47px;
    border-radius: 50%;
    overflow: hidden;
}

.follow-buy-me .myInfo .t .t1 {
    margin-left: 11px
}

.follow-buy-me .myInfo .t .t1 .yellow {
    color: #f2a218;
    border: 1px solid #f2a218;
    font-size: 13px;
    padding: 1px 4px
}

.follow-buy-me .myInfo .t .t1 .orange {
    color: #f56d0c;
    border: 1px solid #f56d0c;
    font-size: 13px;
    padding: 1px 4px
}

.follow-buy-me .myInfo .t .t1 .red {
    color: #d90012;
    border: 1px solid #d90012;
    font-size: 13px;
    padding: 1px 4px
}