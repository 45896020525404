.draw-money .btn-container {
    display: flex;
    justify-content: center;
}

.draw-money .btn-container div {
    width: 387px;
    height: 45px;
    line-height: 45px;
    background: #fc4b27;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: #fff;
}
.draw-money .tips {
    padding: 18px;
}
