.user-bet-detail .uni-image>img {
    width: 100%;
    height: 100%;
}

.user-bet-detail.bigView {
    display: flex;
    flex-flow: column;
    min-height: 100vh;
    font-size: 15px;
}
.user-bet-detail .detail-top {
    background-color: #fff;
    position: relative;
    padding-bottom: 1px;
}
.user-bet-detail .topTtps {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 29px;
    line-height: 29px;
    margin: 1px auto;
    background: #ffaf00;
    color: white;
}
.user-bet-detail .topTtps uni-text {
    position: relative;
    display: inline-block;
    width: 92%;
    height: 29px;
    line-height: 29px;
    background: #eef6ff;
    border-radius: 5px;
    font-size: 11px;
    font-weight: 400;
    color: #3193fd;
    padding: 0 12px;
}
.user-bet-detail .detail-top .d1 {
    position: relative;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.user-bet-detail .detail-top .d1 .icon {
    width: 33px;
    height: 33px;
    margin-left: 17px;
    margin-right: 5px;
}
.user-bet-detail .detail-top .d1 h3 {
    margin-right: 4px;
}
.user-bet-detail .detail-top .bottom {
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 0 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}
.user-bet-detail .detail-top .bottom .r1 {
    font-weight: 900;
    font-size: 17px;
}
.user-bet-detail .u-m-t-10 {
    margin-top: 4px!important;
}

.user-bet-detail .item {
    margin-top: 4px;
    background-color: #fff;
}
.user-bet-detail .item .top {
    padding: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.user-bet-detail .item .top .item_value {
    color: #999;
    font-size: 14px;
    display: inline-block;
    padding: 0 6px;
}
.user-bet-detail .item_class {
    height: 32px;
    border: 1px solid #79d055;
    border-radius: 5px;
    text-align: center;
    line-height: 32px;
    padding: 0 5px;
    margin: 0 10px;
}
.user-bet-detail .item .top .item_value1 {
    color: #77a6eb;
    border: 1px solid #77a6eb;
    font-size: 13px;
    display: inline-block;
    padding: 0 5px;
}
.user-bet-detail .item .top .item_value4 {
    color: #ff8b5f;
    border: 1px solid #ff8b5f;
}

.user-bet-detail .item .top .item_value5 {
    color: #ccc15e;
    border: 1px solid #ccc15e;
}

.user-bet-detail .item .content {
    width: 100vw;
    margin: 0;
    padding: 11px 0;
}

.user-bet-detail .item .content .dltDetail {
    overflow: hidden;
    width: 100%;
}

.user-bet-detail .item .content .dltDetail table {
    width: 100%;
}

.user-bet-detail .item .content .dltDetail table, 
.user-bet-detail .item .content .dltDetail td, 
.user-bet-detail .item .content .dltDetail th {
    border: 1px solid #eaeaea;
    border-collapse: collapse;
    font-size: 14px;
}

.user-bet-detail .item .content .dltDetail table tr {
    width: 100%;
}

.user-bet-detail .item .content .dltDetail table th, 
.user-bet-detail .item .content .dltDetail td th, 
.user-bet-detail .item .content .dltDetail th th {
    background: #fafafa;
    height: 39px;
    color: #999;
}

.user-bet-detail .item .content .dltDetail table td, 
.user-bet-detail .item .content .dltDetail td td, 
.user-bet-detail .item .content .dltDetail th td {
    overflow: hidden;
    word-break: break-all;
    color: #333;
    font-weight: 400;
    padding: 19px 0;
    text-align: center;
}

.user-bet-detail .item .content .dltDetail table td:nth-child(1), 
.user-bet-detail .item .content .dltDetail td td:nth-child(1), 
.user-bet-detail .item .content .dltDetail th td:nth-child(1) {
    width: 26%;
}

.user-bet-detail .item .content .dltDetail table td:nth-child(2), 
.user-bet-detail .item .content .dltDetail td td:nth-child(2), 
.user-bet-detail .item .content .dltDetail th td:nth-child(2) {
    padding: 18px 5px;
    width: 54%;
}

.user-bet-detail .item .content .dltDetail table td:nth-child(2) span, 
.user-bet-detail .item .content .dltDetail td td:nth-child(2) span, 
.user-bet-detail .item .content .dltDetail th td:nth-child(2) span {
    margin-left: 4px;
    font-size: 15px;
}

.user-bet-detail .item .content .dltDetail p {
    margin: 0 11px;
    padding: 11px 0;
}

.user-bet-detail .item .content .dltDetail p span {
    padding-right: 6px;
}

.user-bet-detail .item .content .c_red {
    color: #fc4b27;
}

.user-bet-detail .auto-fill {
    background: #fff;
    flex: 1;
}
.user-bet-detail .pj {
    margin-top: 4px;
    padding: 8px;
    background-color: #fff;
}
.user-bet-detail .pj .title {
    font-weight: 900;
    padding: 8px 0px;
}
.user-bet-detail .noticeDetail {
    margin-top: 4px;
    padding: 8px;
    background-color: #fff;
}
.user-bet-detail .btnbox2 {
    width: 100vw;
    height: 64px;
    background: #fff;
    font-size: 13px;
    padding-top: 4px;
}
.user-bet-detail .bg-primary {
    background-color: #fc4b27;
}