.bonus-optimization {
    font-size: 15px;
    height: 100%;
    background-color: #eaeaea;
}

.bonus-optimization .jz_tzjz {
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 13px;
    color: #666;
}

.bonus-optimization .planBuyBox {
    background: #fff;
    height: 42px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.bonus-optimization .tc {
    text-align: center;
}

.bonus-optimization .planBuyBox .minus, .planBuyBox .add {
    width: 25px;
    height: 25px;
    display: inline-block;
    background: url(../../assets/sprite.9d410b6f.png) no-repeat;
    -webkit-background-size: 218px;
    background-size: 218px;
    vertical-align: top;
}

.bonus-optimization .planBuyBox .minus {
    background-position: -65px -121px;
    margin-left: 10px;
}

.bonus-optimization .planBuyBox .add {
    background-position: -92px -121px;
}

.bonus-optimization .planBuyBox .ipt {
    display: inline-block;
    width: 70px;
    height: 24px;
    border: 1px solid #999;
    margin: 0 5px;
    text-align: center;
}

.bonus-optimization .uni-input-input {
    position: relative;
    display: block;
    height: 100%;
    background: none;
    color: inherit;
    opacity: 1;
    font: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    text-align: inherit;
    text-indent: inherit;
    text-transform: inherit;
    text-shadow: inherit;
}

.bonus-optimization .jjyhTabBox {
    margin-top: 10px;
    display: flex;
    background: #fff;
    height: 42px;
}

.bonus-optimization .jjyhTabBox .item {
    flex: 1;
    text-align: center;
    line-height: 42px;
    position: relative;
}

.bonus-optimization .jjyhTabBox .item:after {
    content: "";
    position: absolute;
    right: 0;
    top: calc(50% - 12px);
    width: 1px;
    height: 25px;
    background: #eaeaea;
}

.bonus-optimization .jjyhTabBox .item.cur {
    color: #fc4b27;
}

.bonus-optimization .jjyhTabBox .item.cur:before {
    content: "";
    position: absolute;
    left: calc(50% - 30px);
    width: 60px;
    height: 2px;
    bottom: 0;
    background: #fc4b27;
}

.bonus-optimization .jjyhTable {
    font-size: 12px;
    overflow: hidden;
    background: #fff;
}

.bonus-optimization .jjyhTable table {
    text-align: center;
    border-collapse: collapse;
    border-spacing: 0;
}

.bonus-optimization .jjyhTable table tr {
    border-top: #eaeaea solid 1px;
}

.bonus-optimization .jjyhTable table td {
    padding: 10px 5px;
    line-height: 22px;
    white-space: nowrap;
    border-right: #eaeaea solid 1px;
}

.bonus-optimization .jjyhTable table td p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 auto;
}

.bonus-optimization .jjyhTable table td .minus,
.bonus-optimization .jjyhTable table td .add {
    width: 25px;
    height: 25px;
    display: inline-block;
    background: url(../../assets/sprite.9d410b6f.png) no-repeat;
    -webkit-background-size: 218px;
    background-size: 218px;
    vertical-align: top;
}

.bonus-optimization .jjyhTable table td .minus {
    background-position: -65px -121px;
}

.bonus-optimization .jjyhTable table td .add {
    background-position: -92px -121px;
}

.bonus-optimization .jjyhTable table td .ipt {
    display: inline-block;
    width: 40px;
    height: 24px;
    border: 1px solid #999;
    text-align: center;
}

.bonus-optimization .c_red {
    color: red;
}

.bonus-optimization .bottom-bar-top {
    background-color: #fff;
    font-size: 15px;
    margin-top: 6px;
    width: 100vw;
    height: 41px;
    line-height: 41px;
    text-align: center;
    position: fixed;
    bottom: 50px;
    right: 0;
    z-index: 9999999!important;
}

.bonus-optimization .txt-primary {
    color: #fc4b27;
}

.bonus-optimization .bottom-bar-next {
    width: 100vw;
    height: 51px;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 9999999!important;
}
.bonus-optimization .bottom-bar-next .bottom-bar-foot {
    border-top: 1px solid #eaeaea;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 51px;
    line-height: 51px;
    background-color: #fce3de;
    text-align: center;
}
.bonus-optimization .bottom-bar-next .bottom-bar-foot .bottom-bar-foot-left {
    min-width: 100px;
    display: flex;
    padding-left: 14px;
    justify-content: flex-start;
    align-items: center;
}

.bonus-optimization .fee {
    color: #ff3737;
}

.bonus-optimization .bottom-bar-next .bottom-bar-foot .bottom-bar-foot-tops {
    display: flex;
    width: 30%;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    height: 51px;
    line-height: 51px;
}

.bonus-optimization .bottom-bar-next .bottom-bar-foot .bottom-bar-foot-tops .bottom-bar-foot-btn {
    margin-left: 0!important;
    display: inline-block;
    border: 1px solid #fc4b27;
    color: #fc4b27;
    height: 27px;
    width: 59px;
    line-height: 25px;
    border-radius: 13px;
}

.bonus-optimization .bottom-bar-next .bottom-bar-foot .bottom-bar-foot-next {
    width: 30vw;
    font-size: 18px;
    line-height: 51px;
    background-color: #fc4b27;
    color: #fff;
}