.agent-my-users .container {
    background-color: #fff;
    margin-top: 11px;
    padding: 11px
}

.agent-my-users .container .title {
    text-align: center
}

.agent-my-users .container .cont {
    margin-top: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center
}

.agent-my-users .container .cont .t1 {
    width: 30%
}

.agent-my-users .container .cont .t2 {
    width: 20%
}

.agent-my-users .container .cont .t3 {
    width: 30%
}

.agent-my-users .container .cont .t4 {
    width: 20%
}

.agent-my-users .container .item {
    margin-top: 11px;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    color: #999;
    border-bottom: 1px solid #eaeaea
}

.agent-my-users .container .item .t1 {
    width: 30%
}

.agent-my-users .container .item .t2 {
    width: 20%
}

.agent-my-users .container .item .t3 {
    width: 30%
}

.agent-my-users .container .item .t4 {
    width: 20%
}

.agent-my-users .container .item .t4 .checkBtn {
    padding: 3px 11px;
    border-radius: 4px;
    background: #fc4b27;
    color: #fff
}

.agent-my-users .topBox {
    background: #fff
}

.agent-my-users .topBox .top {
    padding: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center
}

.agent-my-users .topBox .top .dataBox {
    text-align: center;
    width: 45%;
    padding: 11px 0;
    border: 1px solid #ccc;
    border-radius: 6px
}

.agent-my-users .topBox .search {
    padding: 11px
}

.agent-my-users .topBox .box {
    margin-top: 11px;
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    text-align: center;
    font-size: 15px;
    color: #9599a6;
    font-weight: 700
}

.agent-my-users .topBox .box .left .title.active {
    color: #fc4b27;
    border-bottom: 3px solid #fc4b27
}

.agent-my-users .topBox .box .right .title.active {
    color: #fc4b27;
    border-bottom: 3px solid #fc4b27
}

.agent-my-users .fee {
    color: #ff3737;
}