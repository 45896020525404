.match-list-day ul {
    list-style: none;
}

.match-list-day .top {
    background: #fc4b27;
    position: relative;
    padding: .2rem 0 0 0;
}

.match-list-day .zqnr {
    width: 3rem;
    background: rgba(255, 255, 255, .6);
    border-radius: .33rem;
    margin: 0 auto .2rem;
}

.match-list-day .zqnr ul {
    overflow: hidden;
}

.match-list-day .zqnr li {
    float: left;
    width: 50%;
}

.match-list-day .zqnr li div {
    display: block;
    text-align: center;
    font-size: .3rem;
    color: #fff;
    line-height: .66rem;
    border-radius: .33rem;
}

.match-list-day .zqnr li.cur div {
    color: #fc4b27;
    background: #fff;
}

.match-list-day .jcfl ul {
    overflow: hidden;
}

.match-list-day .jcfl li {
    float: left;
    width: 50%;
    text-align: center;
}

.match-list-day .jcfl div {
    display: inline-block;
    padding: 0 .33rem;
    padding: 0 .33rem;
    color: rgba(255, 255, 255, .6);
    line-height: .8rem;
    position: relative;
}

.match-list-day .jcfl div:before {
    position: absolute;
    content: "";
    border-bottom: 4px solid #fff;
    left: 0;
    bottom: 0;
    width: 0;
}

.match-list-day .jcfl li.cur div:before {
    width: 100%;
}

.match-list-day .jcfl li.cur div {
    color: #fff;
}

.match-list-day .wzgg {
    position: absolute;
    right: .3rem;
    top: .35rem;
    font-size: .26rem;
    color: #fff;
}

.match-list-day .kjrq {
    /* padding: .3rem 0 .5rem; */
    background: #fff;
    position: relative;
    overflow: auto;
}

.match-list-day .rqbox h2 {
    font-size: .26rem;
    color: #999;
    margin-bottom: .1rem;
    text-align: center;
}

.match-list-day .rqbox p {
    font-size: .24rem;
    color: #999;
    text-align: center;
}

.match-list-day .bsnr {
    flex: 1 1;
    overflow: auto;
}

.match-list-day .bsnr li {
    padding-bottom: .2rem;
}

.match-list-day .bsnrbox {
    background: #fff;
    padding: .35rem .3rem;
}

.match-list-day .bsnrbox h2 {
    font-size: .26rem;
    color: #999;
    line-height: .6rem;
}

.match-list-day .bsnrbox h2 span {
    display: inline-block;
    vertical-align: middle;
    margin: -0.04rem .2rem 0 0;
    border-radius: .1rem;
    padding: 0 .16rem;
    border-radius: .08rem;
    background: #f8f8f8;
}

.match-list-day .bsnrbox h2 b {
    color: #ff0000;
}

.match-list-day .bstext {
    margin-top: .15rem;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.match-list-day .bstext .odds-box {
    flex: 1;
    text-align: center;
}

.match-list-day .bstext .odds-box .odds-detail {
    border: solid 1px;
}
.match-list-day .bcbs {
    position: absolute;
    /* top: .1rem; */
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
}

.match-list-day .bcbs h3 {
    font-weight: bold;
    font-size: .32rem;
    color: #FF0000;
    /* margin-bottom: .24rem; */
}

.match-list-day .bcbs p {
    font-size: .26rem;
    color: #FF0000;
}

.match-list-day .bs_left img {
    display: inline-block;
    vertical-align: middle;
    width: .56rem;
    margin: -0.04rem .1rem 0 0;
}

.match-list-day .bs_left h3 {
    color: #000;
    font-weight: bold;
    font-size: .3rem;
}

.match-list-day .bs_left p {
    font-size: .24rem;
    color: #999;
    padding-top: .5rem;
}

.match-list-day .bs_right {
    text-align: right;
}

.match-list-day .bs_right img {
    display: inline-block;
    vertical-align: middle;
    width: .56rem;
    margin: -0.04rem .1rem 0 0;
}

.match-list-day .bs_right h3 {
    color: #000;
    font-weight: bold;
    font-size: .3rem;
}

.match-list-day .bs_right p {
    font-size: .24rem;
    color: #999;
    padding-top: .5rem;
}

.match-list-day .bcbs h4 {
    font-size: .36rem;
    color: #999;
    font-weight: bold;
    /* margin-top: .2rem; */
}

.match-list-day .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.match-list-day .swiper-wrapper {
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
}

.match-list-day .swiper-slide {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}