div {
    box-sizing: border-box;
}

.three-background {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.adm-tabs-content {
    padding: 0;
}

.changeTis {
    height: 70px !important;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
}

.changeTis .changeTis-view {
    width: 30vw;
    height: 38px;
    line-height: 38px;
    text-align: center;
    margin: 5px;
    border: 1px solid #b5b5b5;
}

.changeTis .active {
    color: #fc4b27;
    border-color: #fc4b27;
}

.number-red {
    border-top: #eaeaea solid 1px;
    width: 100vw;
}

.number-red .number-red-top {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    color: #999;
    padding: 10px 16px 8px 16px;
    font-size: 13px;
    box-sizing: border-box;
}

.number-red .number-red-top .number-red-top-last {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    color: #fc4b27;
    text-align: center;
}

.number-red .number-red-top .number-red-top-last :nth-child(1) {
    border: 1px solid #fc4b27;
    width: 63px;
    margin-right: 10px;
}

.number-red .number-red-foot {
    height: 30vw;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    padding: 0 7px;
    border-bottom: #eaeaea solid 1px;
}

.number-red .number-red-foot .number-red-foot-text {
    height: 23vw;
    width: 17vw;
    text-align: center;
    line-height: 5vh;
    color: #999;
    font-size: 16px;
}

.number-red .number-red-foot .number-red-foot-view {
    height: 23vw;
    width: 83vw;
}

.number-red .number-red-foot .number-red-foot-view .number-red-foot-num {
    float: left;
    width: calc((100vw - 14px)/ 7 - 14px);
    height: calc((100vw - 14px)/ 7 - 14px);
    border: #eaeaea solid 1px;
    border-radius: 50%;
    text-align: center;
    line-height: calc((100vw - 14px)/ 7 - 14px);
    margin: 0 7px 7px 7px;
    font-size: 15px;
    color: red;
    box-sizing: border-box;
}

.theRed {
    background: #fe0000;
    border-color: #fe0000;
    color: #fff !important;
}

.theBlue {
    background: #1185d2;
    border-color: #1185d2;
    color: #fff !important;
}

.number-red .number-red-btn {
    width: 100vw;
    height: 126px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    padding: 0 8px;
}

.number-red .number-red-btn div {
    width: 43vw;
    height: 43px;
    border: 1px solid #eaeaea;
    line-height: 43px;
    text-align: center;
    border-radius: 9px;
}

.bottom-bar {
    width: 100vw;
    position: fixed;
    z-index: 9999;
    right: 0;
    bottom: 0;
}

.bottom-bar .bet_bl {
    height: 43px;
    line-height: 43px;
    text-align: center;
    background: #f4f5f6;
    box-shadow: 0 -1px 4px 0 rgba(168, 167, 167, .25);
    font-size: 15px;
    font-weight: 400;
    color: #444;
}

.bottom-bar .bottom-bar-foot {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 51px;
    line-height: 51px;
    background-color: #fce3de;
    text-align: center;
}

.bottom-bar .bottom-bar-foot .bottom-bar-foot-top {
    margin-left: 9px;
    display: flex;
    align-items: center;
}

.bottom-bar .bottom-bar-foot .bottom-bar-foot-top .uni-image {
    width: 32px;
}

.txt-primary {
    color: #fc4b27;
}

.bottom-bar .bottom-bar-foot .bottom-bar-foot-next {
    width: 30vw;
    line-height: 51px;
    background-color: #fc4b27;
    font-size: 18px;
    color: #fff;
}