.home {
    flex: 1 1;
    overflow-y: scroll;
}

.home .home-tools {
    background-color: var(--whole-page-background);
    border-radius: 8px;
    margin: 10px;
    padding: 14px;
}

.home-tools-item-label {
    font-size: 14px;
    margin-top: 4px;
}

.home-tools-item-tips {
    font-size: 10px;
    color: var(--color);
}

.home-reward-see-more {
    color: #FFFFFF;
}

.home-mask-phone {
    margin-top: 6px;
    margin-bottom: 6px;
}

.home-reward-value {
    background-color: #ff7a45;
    border-radius: 40px;
    color: #FFFFFF;
    font-size: 10px;
    padding: 4px 12px;
}

.home-tools-icon {
    width: 50px;
    height: 50px;
}

.home-tools-image {
    width: 100%;
}

.home .topBox {
    position: relative;
    margin: 0 10px;
    border-radius: 10px;
}

.home .topBox img {
    width: 100%;
}

.home .topBox .title .title-top span {
    margin-left: 11px;
    font-size: 21px;
    font-family: Segoe UI;
    font-weight: 600;
    color: #fff;
    opacity: 1;
    display: inline-block;
    margin-top: 20px;
}

.home .topBox .title .title-top .uni-image {
    width: 30px;
    height: 28px;
    background: #fff;
    opacity: 1;
    background: transparent;
    border-radius: 0;
}
.home .uni-image>div, .home .uni-image>img {
    width: 100%;
    height: 100%;
}

.home .uni-image>img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}