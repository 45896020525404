.follow-buy-customize {}
.follow-buy-customize .t-tips {
    padding: 8px;
    font-size: 8px;
    text-align: center;
    color: #999;
}
.follow-buy-customize .gd-box {
    background-color: #fff;
}
.follow-buy-customize .gd-box .title {
    padding: 8px;
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.follow-buy-customize .gd-box .title .left {
    display: flex;
    align-items: center;
}
.follow-buy-customize .gd-box .title .left .uni-image {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}
.follow-buy-customize .gd-box .title .right {
    display: flex;
    align-items: center;
}
.follow-buy-customize .gd-box .title .right .uni-image {
    width: 12px;
    height: 12px;
}
.follow-buy-customize .gd-box .title .right span {
    margin-left: 8px;
}
.follow-buy-customize .gd-box .bs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid #f5f5f5;
}
.follow-buy-customize .gd-box .bs .right {
    display: flex;
    align-items: center;
}
.follow-buy-customize .gd-box .bs .right .uni-image {
    width: 17px;
    height: 17px;
}

.follow-buy-customize .gd-box .bs .right .u-input {
    box-sizing: border-box;
    margin: 0 8px;
    width: 59px;
    font-size: 8px;
    text-align: left;
    border: 1px solid #000;
}
.follow-buy-customize .u-input--border {
    border-radius: 2px;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
}
.follow-buy-customize .u-input__input {
    font-size: 11px;
    color: #303133;
    flex: 1;
}
.follow-buy-customize .uni-input-input, .uni-input-placeholder {
    width: 100%;
}
.follow-buy-customize .uni-input-form, .uni-input-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.follow-buy-customize .uni-input-placeholder {
    position: absolute;
    top: auto!important;
    left: 0;
    color: grey;
    overflow: hidden;
    text-overflow: clip;
    white-space: pre;
    word-break: keep-all;
    pointer-events: none;
    line-height: inherit;
}

.follow-buy-customize .uni-input-input {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    background: none;
    color: inherit;
    opacity: 1;
    font: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    text-align: inherit;
    text-indent: inherit;
    text-transform: inherit;
    text-shadow: inherit;
}

.follow-buy-customize .gd-box .money {
    padding: 8px;
}
.follow-buy-customize .gd-box .money .set {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.follow-buy-customize .gd-box .money .set .right {
    align-items: center;
    margin-top: 4px;
}
.follow-buy-customize .gd-box .money .setMoney {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.follow-buy-customize .gd-box .money .setMoney .right {
    display: flex;
    align-items: center;
}
.follow-buy-customize .gd-box .money .setMoney .right .u-input {
    box-sizing: border-box;
    margin: 0 8px;
    width: 76px;
    font-size: 8px;
    text-align: left;
    border: 1px solid #000;
    border-radius: 4px;
}

.follow-buy-customize .f-tips {
    margin: 4px 8px;
    color: #999;
    font-size: 8px;
}
.follow-buy-customize .btn {
    margin: 21px 8px;
    background-color: #fc4b27;
    text-align: center;
    padding: 8px 0;
    border-radius: 5px;
    color: #fff;
    font-size: 15px;
}