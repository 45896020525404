.chase-num-detail.bigView {
    background-color: #eaeaea
}

.chase-num-detail .u-m-l-20 {
    margin-left: 10px!important;
}
.chase-num-detail .detailHeader {
    background-color: #fff;
    width: 100vw;
    margin-bottom: 5px;
}
.chase-num-detail .detailHeader .detailHeader-top {
    width: 100vw;
    padding: 13px 5px 10px 18px;
}
.chase-num-detail .detailHeader .detailHeader-top:first-child {
    font-size: 19px;
    font-weight: 500;
}

.chase-num-detail .detailHeader .detailHeader-top :nth-child(2) {
    font-size: 14px;
    margin-top: 5px;
    color: #999;
    font-weight: 500;
    display: inline-block;
}
.chase-num-detail .detailHeader .detailHeader-footer {
    width: 100%;
    height: 87px;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    padding: 0 10px;
}
.chase-num-detail .detailHeader .detailHeader-footer .detailHeader-footer-top {
    border-radius: 10px;
    border: 1px solid #c7c7c8;
    width: 30%;
    height: 70px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    counter-reset: #000;
}
.chase-num-detail .detailHeader .detailHeader-footer .detailHeader-footer-top :first-child {
    font-size: 20px;
    color: #87115d;
}
.chase-num-detail .detailHeader .detailHeader-footer .detailHeader-footer-top :last-child {
    margin-top: 4px;
}
.chase-num-detail .detailHeader .detailHeader-footer .detailHeader-footer-footer {
    border-radius: 10px;
    border: 1px solid #c7c7c8;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 70px;
    display: flex;
    flex-flow: column;
}
.chase-num-detail .detailHeader .detailHeader-footer .detailHeader-footer-footer :first-child {
    font-size: 21px;
}
.chase-num-detail .detailHeader .detailHeader-footer .detailHeader-footer-mian {
    border-radius: 10px;
    border: 1px solid #c7c7c8;
    width: 30%;
    height: 70px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}
.chase-num-detail .detailHeader .detailHeader-footer .detailHeader-footer-mian :first-child {
    font-size: 20px;
    color: #999;
}
.chase-num-detail .detailHeader .detailHeader-footer .detailHeader-footer-mian :last-child {
    margin-top: 3px;
    font-size: 13px;
}

.chase-num-detail .detailMain {
    background-color: #fff;
    width: 100vw;
}
.chase-num-detail .detailMain .detailMain-top {
    height: 54px;
    width: 100vw;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    color: #000;
    padding: 8px;
}
.chase-num-detail .item {
    margin-top: 11px;
    background-color: #fff;
}
.chase-num-detail .item .dltDetail {
    overflow: hidden;
}
.chase-num-detail .item .dltDetail table {
    width: 100%;
}
.chase-num-detail .item .dltDetail table, .item .dltDetail td, .item .dltDetail th {
    border: 1px solid #eaeaea;
    border-collapse: collapse;
    font-size: 14px;
}

.chase-num-detail .item .dltDetail table tr, .item .dltDetail td tr, .item .dltDetail th tr {
    font-size: 14px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
}
.chase-num-detail .item .dltDetail table th, .item .dltDetail td th, .item .dltDetail th th {
    background: #dee0e1;
    height: 39px;
    color: #999;
}

.chase-num-detail .item .dltDetail table td, .item .dltDetail td td, .item .dltDetail th td {
    overflow: hidden;
    word-break: break-all;
    color: #333;
    font-weight: 400;
    padding: 19px 0;
    text-align: center;
}

.chase-num-detail .item .dltDetail table td:nth-child(1), .item .dltDetail td td:nth-child(1), .item .dltDetail th td:nth-child(1) {
    width: 26%;
}

.chase-num-detail .item .dltDetail table td:nth-child(2), .item .dltDetail td td:nth-child(2), .item .dltDetail th td:nth-child(2) {
    padding: 18px 5px;
    width: 54%;
}

.chase-num-detail .item .dltDetail table td:nth-child(2) uni-text, .item .dltDetail td td:nth-child(2) uni-text, .item .dltDetail th td:nth-child(2) uni-text {
    margin-left: 4px;
    font-size: 15px;
}

.chase-num-detail .detailFoot {
    background-color: #fff;
    margin-top: 4px;
    width: 100vw;
}
.chase-num-detail .detailFoot .detailFoot-top {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    color: #000;
    padding: 8px 7px 19px 7px;
    font-size: 16px;
}
.chase-num-detail .detailFoot .detailFoot-top :last-child {
    color: #333;
}
.chase-num-detail .detailFoot .detailFoot-mian {
    display: flex;
    flex-flow: row;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    text-align: center;
}
.chase-num-detail .detailFoot .detailFoot-mian :nth-child(1) {
    width: 54px;
    flex: 1;
}
.chase-num-detail .detailFoot .detailFoot-mian :nth-child(2) {
    width: 27px;
    flex: 1;
}
.chase-num-detail .detailFoot .detailFoot-mian :nth-child(3) {
    white-space: nowrap;
    flex: 2;
}
.chase-num-detail .detailFoot .detailFoot-mian :nth-child(4) {
    flex: 1;
}
.chase-num-detail .detailFoot-foot {
    width: 100vw;
}
.chase-num-detail .detailFoot-foot .detailFoot-foot-list {
    position: relative;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    text-align: center;
}
.chase-num-detail .detailFoot-foot .detailFoot-foot-list :nth-child(1) {
    flex: 1;
}
.chase-num-detail .detailFoot-foot .detailFoot-foot-list :nth-child(2) {
    flex: 1;
}
.chase-num-detail .detailFoot-foot .detailFoot-foot-list :nth-child(3) {
    word-break: break-all;
    flex: 2;
}
.chase-num-detail .detailFoot-foot .detailFoot-foot-list :nth-child(4) {
    flex: 1;
}

.chase-num-detail .btnbox {
    padding: 23px 11px 54px;
}
