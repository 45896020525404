
.pointBox {
    background: linear-gradient(180deg,#fc4b27 15%,transparent);
    width: 100vw;
    padding: 22px;
}
.pointBox .myH {
    height: 230px
}

.pointBox .myheader {
    background-size: 100% 100%;
    border-radius: 6px
}

.pointBox .myheader .title {
    text-align: center;
    color: #fc4b27;
    font-size: 18px;
    font-weight: 700;
    padding-top: 11px
}

.pointBox .myheader .userInfo {
    margin-top: 22px;
    text-align: center
}

.pointBox .myheader .userInfo .uni-image {
    width: 90px;
    height: 90px;
    margin: 0 auto;
    overflow: hidden;
}

.pointBox .myheader .userInfo .name {
    margin-top: 11px;
    font-size: 18px;
    color: #fff
}

.pointBox .myheader .my {
    margin-top: 51px
}

.pointBox .myheader .my .topBtms {
    display: flex;
    justify-content: space-around;
    align-items: center
}

.pointBox .myheader .my .topBtms .img1 {
    width: 45px;
    height: 45px
}

.pointBox .myheader .my .topBtms .img2 {
    width: 7px;
    height: 18px
}

.pointBox .myheader .my .topBtms .topBtmsd1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    flex: 1;
    background: linear-gradient(180deg, #ffb46e, #ffc94b);
    border-radius: 10px;
}

.pointBox .myheader .my .topBtms .topBtmsd1:last-child {
    margin-left: 5px;
    margin-right: 0px;
    background: linear-gradient(180deg, #ffbbbf, #f9865d)
}

.pointBox .myheader .my .topBtms .topBtmsd1 .s {
    padding: 17px;
    width: 100%;
    font-size: 18px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center
}

.pointBox .myheader .my .topBtms .topBtmsd1 .s .s1 {
    color: #fff;
    font-size: 14px;
    white-space: nowrap
}

.pointBox .myheader .my .topBtms .topBtmsd1 .s .s1 :last-child {
    margin-left: 3px
}

.pointBox .myheader .my .topBtms .topBtmsd1 .s .s2 {
    font-size: 18px;
    margin: 11px 0;
    color: #fc4b27
}

.c1 {
    white-space: nowrap
}