.active22 {
    background: #fc4b27 !important;
    color: #fff !important
}

.buyView {
    width: 100vw;
    background-color: #f2f4f7
}

.buyView .buyView-top {
    width: 100%;
    height: 53px;
    background-color: #fff;
    padding: 10px 15px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center
}

.buyView .buyView-top> :nth-child(1) {
    height: 53px;
    line-height: 53px;
    font-size: 14px;
    font-weight: 700;
    color: #16171a
}

.buyView .buyView-top> :nth-child(2) {
    height: 53px;
    line-height: 53px;
    font-size: 18px;
    font-weight: 700;
    color: #fc4b27
}

.buyView .buyView-TopBtn {
    width: 100%;
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #fff
}

.buyView .buyView-TopBtn .buyView-TopBtn-top {
    width: 100%;
    height: 64px;
    display: flex;
    flex-flow: row nowrap
}

.buyView .buyView-TopBtn .buyView-TopBtn-top> :nth-child(1) {
    width: 96px;
    height: 64px;
    font-weight: 700;
    color: #16171a;
    font-size: 14px;
    line-height: 64px
}

.buyView .buyView-TopBtn .buyView-TopBtn-top> :nth-child(2) {
    display: flex;
    width: 250px;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center
}

.buyView .buyView-TopBtn .buyView-TopBtn-top> :nth-child(2) div .active {
    background: #fc4b27;
    color: #fff
}

.buyView .buyView-TopBtn .buyView-TopBtn-top> :nth-child(2) div {
    width: 60px;
    height: 32px;
    line-height: 32px;
    background: #f2f4f7;
    border-radius: 3px;
    margin: 0 4px;
    text-align: center;
    font-size: 14px;
    color: #9599a6
}

.buyView .buyView-TopBtn .buyView-TopBtn-top> :nth-child(2) div .uni-input {
    width: 60px;
    height: 32px;
    line-height: 32px;
    background: #f2f4f7;
    font-size: 14px
}

.uni-input .uni-input-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.uni-input .uni-input-input, .uni-input .uni-input-placeholder {
    width: 100%;
}

.uni-input .uni-input-placeholder {
    position: absolute;
    top: auto!important;
    left: 0;
    color: grey;
    overflow: hidden;
    text-overflow: clip;
    white-space: pre;
    word-break: keep-all;
    pointer-events: none;
    line-height: inherit;
}

.uni-input .uni-input-input {
    position: relative;
    display: block;
    height: 100%;
    background: none;
    color: inherit;
    opacity: 1;
    font: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    text-align: inherit;
    text-indent: inherit;
    text-transform: inherit;
    text-shadow: inherit;
}

.buyView .buyView-TopBtn .buyView-TopText {
    width: 100%;
    height: 36px;
    border-bottom: 1px solid #eee;
    margin-top: -5px;
    display: flex;
    flex-flow: row nowrap;
    font-weight: 500;
    color: #9599a6;
    font-size: 12px
}

.buyView .buyView-TopBtn .buyView-TopText> :nth-child(1) {
    width: 96px;
    height: 64px;
    font-size: 14px;
    line-height: 64px
}

.buyView .buyView-TopBtn .buyView-foot {
    width: 100%;
    height: 53px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center
}

.buyView .buyView-TopBtn .buyView-foot> :nth-child(1) {
    height: 53px;
    line-height: 53px;
    font-size: 14px;
    font-weight: 700;
    color: #16171a
}

.buyView .buyView-TopBtn .buyView-footShow {
    width: 100%;
    height: 48px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center
}

.buyView .buyView-TopBtn .buyView-footShow> :nth-child(1) {
    height: 48px;
    line-height: 48px;
    font-size: 13px;
    font-weight: 500;
    color: #9599a6
}

.buyView .buyView-TopBtn .buyView-footShow> :nth-child(2) {
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.buyView .buyView-TopBtn .buyView-footShow> :nth-child(2) div.full {
    width: 80px;
    height: 32px;
    line-height: 32px;
    background: #f2f4f7;
    border-radius: 3px;
    margin: 0 4px;
    text-align: center;
    font-size: 14px;
    color: #9599a6
}

.buyView .buyView-TopBtn .buyView-footShow> :nth-child(2) .uni-input {
    width: 70px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    text-align: center;
    color: #9599a6;
    margin: 0 4px;
    border: 1px solid #6d6d6d;
    border-radius: 2px
}

.buyView .buyView-main {
    width: 100%;
    mix-height: 329px;
    margin: 10px 0px;
    padding: 10px 15px;
    background-color: #fff;
    overflow: hidden
}

.buyView .buyView-main .buyView-main-top {
    width: 100%;
    height: 48px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center
}

.buyView .buyView-main .buyView-main-top> :nth-child(1) {
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    font-weight: 700;
    color: #16171a
}

.buyView .buyView-main .buyView-main-btn {
    display: flex;
    height: 55px;
    width: 100%;
    margin-top: 5px;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee
}

.buyView .buyView-main .buyView-main-btn div {
    width: 60px;
    height: 32px;
    line-height: 32px;
    background: #f2f4f7;
    border-radius: 3px;
    margin: 0 4px;
    text-align: center;
    font-size: 14px;
    color: #9599a6
}

.buyView .buyView-main .buyView-main-bor {
    width: 100%;
    height: 68px;
    display: flex;
    border-bottom: 1px solid #eee;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center
}

.buyView .buyView-main .buyView-main-bor> :nth-child(1) {
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    font-weight: 700;
    color: #16171a
}

.buyView .buyView-main .buyView-main-bor> :nth-child(2) {
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.buyView .buyView-main .buyView-main-bor> :nth-child(2) div {
    width: 80px;
    height: 32px;
    line-height: 32px;
    background: #f2f4f7;
    border-radius: 3px;
    margin: 0 4px;
    text-align: center;
    font-size: 14px;
    color: #9599a6
}

.buyView .buyView-main .buyView-main-text {
    width: 100%;
    height: 143px
}

.buyView .buyView-main .buyView-main-text> :nth-child(1) {
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    font-weight: 700;
    color: #16171a
}

.buyView .buyView-main .buyView-main-text> :nth-child(2) {
    height: 80px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    color: #16171a;
    line-height: 14px;
    background-color: #f5f6fa;
    padding: 10px;
    border-radius: 4px
}

.buyView .buyView-footBtn {
    width: 100%;
    padding-left: 15px;
    background-color: #fff
}

.buyView .buyView-footBtn .buyView-footBtn-top {
    width: 100%;
    height: 40px;
    line-height: 40px
}

.buyView .buyView-footBtn .buyView-footBtn-btn {
    width: 100%;
    height: 55px;
    line-height: 55px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end
}

.buyView .buyView-footBtn .buyView-footBtn-btn> :nth-child(1) {
    height: 55px;
    line-height: 55px
}

.buyView .buyView-footBtn .buyView-footBtn-btn> :nth-child(1) span {
    height: 40px;
    line-height: 40px
}

.buyView .buyView-footBtn .buyView-footBtn-btn> :nth-child(1)> :nth-child(1) {
    font-size: 16px;
    font-weight: 600;
    color: #16171a
}

.buyView .buyView-footBtn .buyView-footBtn-btn> :nth-child(1)> :nth-child(2) {
    font-size: 18px;
    font-weight: 700;
    color: #fc4b27
}

.buyView .buyView-footBtn .buyView-footBtn-btn> :nth-child(2) {
    width: 135px;
    height: 49px;
    background: #fc4b27;
    line-height: 49px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-align: center
}