.listView .listClass {
    width: 100vw;
    height: 76px;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
}

.listView .listClass .listClass-flex {
    width: 100vw;
    height: 109px;
    display: flex;
    flex-flow: row;
    align-self: stretch;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    border-bottom: 1px solid #f1f3f4;
}
.listView .listClass .listClass-flex-top {
    text-align: left;
    width: 35%;
    padding: 16px 0px;
}

.listView .listClass .listClass-flex .listClass-flex-top :last-child {
    margin-top: 8px;
    color: #999;
}

.listView .listClass .listClass-flex .listClass-flex-main {
    text-align: center;
    width: 30%;
    color: red;
    font-size: 16px;
}
.listView .listClass .listClass-flex .listClass-flex-last {
    text-align: right;
    color: #999;
    font-size: 15px;
    width: 35%;
}