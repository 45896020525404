.chat-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.chat-container .chat-title {
    height: 40px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    font-size: 18px;
}

.chat-container .chat-avatar {
    width: 35px;
    height: 35px;
}

.chat-container .chat-name {
    width: 400px;
    font-size: 15px;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.chat-container .chat-main {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1;
    scrollbar-width: thin;
}

.chat-container .chat-main::-webkit-scrollbar {
    width: 0;
}

.chat-container .chat-main .history-loaded {
    text-align: center;
    font-size: 12px;
    color: #cccccc;
    line-height: 20px;
}

.chat-container .chat-main .load {
    text-align: center;
    font-size: 12px;
    color: #d02129;
    line-height: 20px;
    cursor: pointer;
}

.chat-container .history-loading {
    width: 100%;
    text-align: center;
}

.chat-container .time-tips {
    color: #999;
    text-align: center;
    font-size: 12px;
}

.chat-container .message-list {
    padding: 0 10px;
}

.chat-container .message-item {
    display: flex;
}

.chat-container .message-item-checkbox {
    height: 50px;
    margin-right: 15px;
    display: flex;
    align-items: center;
}

.chat-container .input-checkbox {
    position: relative;
}

.chat-container .message-item-checkbox input[type="checkbox"]::before,
.chat-container .message-item-checkbox input[type="checkbox"]:checked::before {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    background: #FFFFFF;
    width: 18px;
    height: 18px;
    border: 1px solid #cccccc;
    border-radius: 50%;
}

.chat-container .message-item-checkbox input[type="checkbox"]:checked::before {
    content: "\2713";
    background-color: #d02129;
    width: 18px;
    color: #FFFFFF;
    text-align: center;
    font-weight: bold;
}

.chat-container .message-item-content {
    flex: 1;
    margin: 5px 0;
    overflow: hidden;
    display: flex;
}

.chat-container .sender-info {
    margin: 0 5px;
}
.chat-container .sender-name {
    margin: 0 5px;
    display: flex;
    align-items: center;
}
.chat-container .sender-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.chat-container .message-content {
    max-width: calc(100% - 100px);
}

.chat-container .message-payload {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.chat-container .self .message-payload {
    justify-content: flex-end;
}

.chat-container .pending {
    background: url("../../assets/chat/images/pending.gif") no-repeat center;
    background-size: 13px;
    width: 15px;
    height: 15px;
}

.chat-container .send-fail {
    background: url("../../assets/chat/images/failed.png") no-repeat center;
    background-size: 15px;
    width: 18px;
    height: 18px;
    margin-right: 3px;
}

.chat-container .message-read {
    color: gray;
    font-size: 12px;
    text-align: end;
    height: 16px;
}

.chat-container .message-unread {
    color: #d02129;
    font-size: 12px;
    text-align: end;
    height: 16px;
}

.chat-container .message-read > div,
.chat-container .message-unread > div {
    font-size: 12px;
}


.chat-container .content-text {
    display: flex;
    align-items: center;
    text-align: left;
    background: #eeeeee;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 8px;
    margin: 3px 0;
    line-height: 25px;
    white-space: pre-line;
    overflow-wrap: anywhere;
    border-radius: 8px;
    word-break: break-all;
}

.chat-container .content-image {
    display: block;
    cursor: pointer;
}

.chat-container .content-image img {
    height: 100%;
}

.chat-container .content-audio {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.chat-container .content-audio .audio-facade {
    min-width: 12px;
    background: #eeeeee;
    border-radius: 7px;
    display: flex;
    font-size: 14px;
    padding: 8px;
    margin: 5px 0;
    line-height: 25px;
    cursor: pointer;
}

.chat-container .content-audio .audio-facade-bg {
    background: url("../../assets/chat/images/voice.png") no-repeat center;
    background-size: 15px;
    width: 20px;
}

.chat-container .content-audio .audio-facade-bg.play-icon {
    background: url("../../assets/chat/images/play.gif") no-repeat center;
    background-size: 20px;
}

.chat-container .content-order {
    border-radius: 5px;
    border: 1px solid #eeeeee;
    padding: 8px;
    display: flex;
    flex-direction: column;
}

.chat-container .content-order .order-id {
    font-size: 12px;
    color: #666666;
    margin-bottom: 5px;
}

.chat-container .content-order .order-body {
    display: flex;
    font-size: 13px;
    padding: 5px;
}

.chat-container .content-order .order-img {
    width: 70px;
    height: 70px;
    border-radius: 5px;
}

.chat-container .content-order .order-name {
    margin-left: 10px;
    width: 135px;
    color: #606164;
}

.chat-container .content-order .order-count {
    font-size: 12px;
    color: #666666;
    flex: 1;
}

.chat-container .content-file {
    width: 240px;
    height: 65px;
    font-size: 15px;
    background: #FFFFFF;
    border: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.chat-container .content-file:hover {
    background: #f1f1f1;
}

.chat-container .file-info {
    width: 194px;
    text-align: left;
}

.chat-container .file-name {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    word-break: break-all;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.chat-container .file-size {
    font-size: 12px;
    color: #ccc;
}

.chat-container .file-img {
    width: 50px;
    height: 50px;
}

.chat-container .message-item .self {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
}

.chat-container .message-item .self .audio-facade {
    flex-direction: row-reverse;
}

.chat-container .message-item .self .audio-facade-bg {
    background: url("../../assets/chat/images/voice.png") no-repeat center;
    background-size: 15px;
    width: 20px;
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.chat-container .message-item .self .play-icon {
    background: url("../../assets/chat/images/play.gif") no-repeat center;
    background-size: 20px;
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.chat-container .message-recalled {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 28px;
    font-size: 13px;
    text-align: center;
    color: grey;
    margin-top: 10px;
}

.chat-container .message-recalled-self {
    display: flex;
}

.chat-container .message-recalled-self span {
    margin-left: 5px;
    color: #D02129;
    cursor: pointer;
}

.chat-container .chat-footer {
    border-top: 1px solid #dcdfe6;
    width: 100%;
    height: 100px;
    background: #FFFFFF;
}

.chat-container .action-delete {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
}

.chat-container .delete-btn {
    width: 25px;
    height: 25px;
    padding: 10px;
    background: #f5f5f5;
    border-radius: 50%;
    cursor: pointer;
    margin-bottom: 10px;
}

.chat-container .action-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.chat-container .action-bar {
    display: flex;
    flex-direction: row;
    padding: 0 10px;
}

.chat-container .action-bar .action-item {
    text-align: left;
    padding: 10px 0;
    position: relative;
}

.chat-container .action-bar .action-item .iconfont {
    font-size: 22px;
    margin: 0 10px;
    z-index: 3;
    color: #606266;
    cursor: pointer;
}

.chat-container .action-bar .action-item .iconfont:focus {
    outline: none;
}

.chat-container .action-bar .action-item .iconfont:hover {
    color: #d02129;
}

.chat-container .emoji-box {
    width: 210px;
    position: absolute;
    top: -111px;
    left: -11px;
    z-index: 2007;
    background: #fff;
    border: 1px solid #ebeef5;
    padding: 12px;
    text-align: justify;
    font-size: 14px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    word-break: break-all;
    border-radius: 4px;
}

.chat-container .emoji-item {
    width: 38px;
    height: 38px;
    margin: 0 2px;
}

.chat-container .input-box {
    padding: 0 10px;
    flex: 1;
}

.chat-container .input-content {
    border: none;
    resize: none;
    display: block;
    padding: 5px 15px;
    box-sizing: border-box;
    width: 100%;
    color: #606266;
    outline: none;
    background: #FFFFFF;
    word-break: break-all;
}

.chat-container .send-box {
    padding: 5px 10px;
    text-align: right;
}

.chat-container .send-button {
    width: 70px;
    height: 30px;
    font-size: 15px;
    border: 1px solid #d02129;
    background-color: #ffffff;
    color: #d02129;
    border-radius: 5px;
}

.chat-container .action-popup {
    width: 848px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -281px;
    background: rgba(51, 51, 51, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-container .action-popup-main {
    width: 150px;
    height: 120px;
    background: #ffffff;
    z-index: 100;
    border-radius: 10px;
    overflow: hidden;
}

.chat-container .action-popup-main .action-item {
    text-align: center;
    line-height: 40px;
    font-size: 15px;
    color: #262628;
    border-bottom: 1px solid #efefef;
    cursor: pointer;
}

.chat-container .image-preview {
    max-width: 750px;
    max-height: 500px;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9998;
}

.chat-container .image-preview img {
    max-width: 750px;
    max-height: 500px;
}

.chat-container .image-preview .close {
    font-size: 50px;
    line-height: 24px;
    cursor: pointer;
    color: #FFFFFF;
    position: absolute;
    top: 10px;
    right: 5px;
    z-index: 1002;
}

.chat-container .order-box {
    width: 848px;
    position: absolute;
    left: -281px;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2007;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(33, 33, 33, 0.7);
}

.chat-container .order-list {
    width: 300px;
    background: #F1F1F1;
    border-radius: 5px;
}

.chat-container .order-list .title {
    font-weight: 600;
    font-size: 15px;
    color: #000000;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chat-container .order-list .title span {
    font-size: 28px;
    font-weight: 400;
    cursor: pointer;
}

.chat-container .order-list .order-item {
    padding: 10px;
    background: #FFFFFF;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.chat-container .order-list .order-id {
    font-size: 12px;
    color: #666666;
    margin-bottom: 5px;
}

.chat-container .order-list .order-body {
    display: flex;
    font-size: 13px;
    justify-content: space-between;
}

.chat-container .order-list .order-img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
}

.chat-container .order-list .order-name {
    width: 160px;
}

.chat-container .order-list .order-count {
    font-size: 12px;
    color: #666666;
    flex: 1;
}