.bigltView {
    padding-bottom: 152px;
    width: 100vw;
    min-height: 100vh;
}
.nbet-top-stop {
    width: 100vw;
    height: 26px;
    text-align: center;
    line-height: 29px;
    color: #999;
}
.nbet-top-btn {
    height: 58px;
    width: 100vw;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    font-size: 18px;
    text-align: center;
    color: #fc4b27;
}

.nbet-top-btn div {
    width: 47vw;
    height: 51px;
    border: 1px solid #fc4b27;
    line-height: 51px;
}

.nbet-top-list {
    width: 100vw;
    min-height: 75px;
    border-bottom: 1px solid #e8ebef;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.nbet-top-list .nbet-top-list-icon {
    min-height: 82px;
    padding-left: 11px;
    line-height: 75px;
}

.nbet-top-list .nbet-top-list-right {
    min-height: 75px;
    padding: 11px 0 11px 5px;
    margin: 0 0px 0 11px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
}

.nbet-top-list .nbet-top-list-right .nbet-top-list-right-nub {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.nbet-top-list .nbet-top-list-right .nbet-top-list-right-nub .nub-view {
    margin-right: 5px;
}

.nbet-top-list .nbet-top-list-right .nbet-top-list-right-money {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 5px;
}

.nbet-top-list .bottom-bar-top-zhuihao-put {
    width: 30vw;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-right: 11px;
}
.qlc-bottom-bar {
    width: 100vw;
    height: 120px;
    border-top: 1px solid #eaeaea;
    position: fixed;
    z-index: 9;
    right: 0;
    bottom: 0;
    background-color: #fff;
}
.qlc-bottom-bar .qlc-bottom-bar-top {
    width: 100vw;
    height: 52px;
    line-height: 52px;
    text-align: center;
    z-index: 11!important;
}
.qlc-bottom-bar .qlc-bottom-bar-top .qlc-bottom-bar-top-zhuihao {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}