.pick-three-table.item .top {
    padding: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.pick-three-table.item .content {
    width: 100vw;
    margin: 0;
    padding: 11px 0;
}

.pick-three-table.item .content .dltDetail {
    overflow: hidden;
    width: 100%;
}

.pick-three-table.item .content .dltDetail table {
    width: 100%;
}

.pick-three-table.item .content .dltDetail table, 
.pick-three-table.item .content .dltDetail td, 
.pick-three-table.item .content .dltDetail th {
    border: 1px solid #eaeaea;
    border-collapse: collapse;
    font-size: 14px;
}

.pick-three-table.item .content .dltDetail table tr {
    width: 100%;
}

.pick-three-table.item .content .dltDetail table th, 
.pick-three-table.item .content .dltDetail td th, 
.pick-three-table.item .content .dltDetail th th {
    background: #fafafa;
    height: 39px;
    color: #999;
}

.pick-three-table.item .content .dltDetail table td, 
.pick-three-table.item .content .dltDetail td td, 
.pick-three-table.item .content .dltDetail th td {
    overflow: hidden;
    word-break: break-all;
    color: #333;
    font-weight: 400;
    padding: 19px 0;
    text-align: center;
}

.pick-three-table.item .content .dltDetail table td:nth-child(1), 
.pick-three-table.item .content .dltDetail td td:nth-child(1), 
.pick-three-table.item .content .dltDetail th td:nth-child(1) {
    width: 26%;
}

.pick-three-table.item .content .dltDetail table td:nth-child(2), 
.pick-three-table.item .content .dltDetail td td:nth-child(2), 
.pick-three-table.item .content .dltDetail th td:nth-child(2) {
    padding: 18px 5px;
    width: 54%;
}

.pick-three-table.item .content .dltDetail table td:nth-child(2) span, 
.pick-three-table.item .content .dltDetail td td:nth-child(2) span, 
.pick-three-table.item .content .dltDetail th td:nth-child(2) span {
    margin-left: 4px;
    font-size: 15px;
}

.pick-three-table.item .content .dltDetail p {
    margin: 0 11px;
    padding: 11px 0;
}

.pick-three-table.item .content .dltDetail p span {
    padding-right: 6px;
}

.pick-three-table.item .content .c_red {
    color: #fc4b27;
}


.pick-three-table.item .noShowClass {
    width: 100vw;
    height: 89px;
    background: #fff;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    color: #333;
    padding-top: 7px;
}

.pick-three-table.item .noShowClass .noShowClass-top {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    height: 38px;
}

.pick-three-table.item .noShowClass .noShowClass-top .noShowClass-top-text {
    margin: 0 6px;
}

.pick-three-table.item .noShowClass .noShowClass-bottom {
    height: 38px;
    line-height: 38px;
}