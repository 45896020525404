.charge-money.bigView {
    width: 100vw;
    min-height: 100vh
}

.charge-money {
    background-color: #fff;
    display: flex;
    flex-direction: column
}

.charge-money .choose .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 16px;
    padding: 5px 0
}

.charge-money .choose .item .d1 {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin: 4px 0
}

.charge-money .choose .item .d1 .uni-image {
    width: 41px;
    height: 41px;
    margin-right: 14px
}

.charge-money .choose .item .d1 .desc {
    max-width: 80%
}

.charge-money .choose .item .d1 .desc .t2 {
    word-break: break-all;
    font-size: 15px;
    color: #9f9f9f;
    font-weight: 500
}

.charge-money .choose .item .icon {
    color: #fc4b27
}

.charge-money .d1 {
    margin: 5px 16px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #161616;
    opacity: 1
}

.charge-money .discount {
    position: absolute;
    right: -10px;
    top: -7px;
    height: 14px;
    line-height: 15px;
    border-radius: 7px;
    font-size: 11px;
    padding: 1px 6px;
    color: #fff;
    background-color: #ff3737
}

.charge-money .diamond_options {
    background: #fff;
    padding: 15px 0 25px
}

.charge-money .diamond_input_wrap {
    width: 70%;
    margin: 0 5px;
    height: 36px;
    border: 1px solid #eaeaea;
    border-radius: 3px;
    background: #fff;
    color: #999;
    font-size: 13px;
    padding-left: 16px;
    padding-left: 10px;
    color: #333;
    display: flex;
    align-items: center
}

.charge-money .diamond_input_wrap .input {
    width: 60%;
    border: 0;
    padding-left: 10px;
    height: 30px;
    line-height: 30px;
    color: #000;
    font-size: 13px
}

.charge-money .diamond_options_hd {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
    font-size: 16px
}

.charge-money .diamond_options_hd {
    padding: 10px 6px
}

.charge-money .diamond_item {
    float: left;
    width: 29%;
    height: 50px;
    margin: 1.5%;
    text-align: center;
    border: 1px solid #fc4b27;
    border-radius: 4px;
    position: relative;
    color: #fc4b27
}

.charge-money .diamond_item.cur {
    background: #fc4b27;
    color: #fff
}

.charge-money .diamond_item h4 {
    font-size: 17px;
    line-height: 50px;
    margin: 0;
    font-weight: 400
}

.charge-money .btn {
    height: 45px;
    position: fixed;
    width: 100%;
    bottom: 0;
    border-radius: 0
}

.charge-money .uni-checkbox-group {
    margin-top: 10px;
    font-size: 13px
}

.charge-money .btm {
    padding: 15px
}

.charge-money .btm .remarkTip {
    margin-left: 8px;
    margin-bottom: 26px
}

.charge-money .btm .tips .txt p {
    padding: 0 8px;
    margin-top: 8px;
    line-height: 20px;
    color: #666
}