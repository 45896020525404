.order-item {
    border-bottom: 1px solid #F5F5F5;
    padding: 12px;
}

.fixed-result {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.fixed-result-top {
    border-top: 1px solid #F5F5F5;
    border-bottom: 1px solid #F5F5F5;
    height: 40px;
}

.option-box {
    border: 1px solid var(--color);
    border-radius: 4px;
    width: 20px;
    height: 20px;
    margin-left: 2px;
    margin-right: 2px;
}

.option-value-box {
    border: 1px solid var(--color);
    border-radius: 4px;
    width: 50px;
    height: 20px;
}

.fixed-result-middle {
    font-size: 16px;
    margin-left: 12px;
    height: 40px;
    line-height: 40px;
}

.fixed-result-middle-yellow {
    color: #fa7914;
}

.fixed-result-bottom-left {
    background-color: #fff3eb;
    color: var(--adm-color-primary);
    padding-left: 20px;
}

.fixed-result-bottom-left div {
    width: 70px;
    text-align: center;
    font-size: 15px;
    height: 30px;
    line-height: 27px;
    border: 1px solid var(--adm-color-primary);
    border-radius: 24px;
    margin-right: 12px;
}

.fixed-result-bottom-right {
    width: 120px;
    background-color: var(--adm-color-primary);
    color: #FFFFFF;
}

.popup-fixed-result-top-item {
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #fff2e8;
    /* color: var(--adm-color-primary);
    border: 1px solid var(--adm-color-primary); */
}

.popup-fixed-result-top-item.active {
    color: var(--adm-color-primary);
    border: 1px solid var(--adm-color-primary);
}

.popup-fixed-result-top {
    margin-left: 12px;
    margin-right: 12px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.popup-fixed-result-top-top {
    font-weight: 600;
}

.popup-fixed-result-top-bottom-left {
    height: 30px;
    color: var(--color);
    background-color: #f0f0f0;
}

.popup-fixed-result-top-bottom-right {
    height: 30px;
    color: var(--color);
    background-color: var(--adm-color-primary);
}