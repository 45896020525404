.basketball-container {
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.basketball-container .adm-list-body-inner {
    padding-bottom: 100px;
}


.basketball-container .adm-tabs {
    flex: 1 1 auto;
    overflow-y: auto;
}

.basketball-container .basketball-item-info div {
    background-color: #FFFFFF;
    padding-top: 8px;
    padding-bottom: 8px;
    margin: 0 auto;
    text-align: center;
}

.basketball-container .basketball-item-info div.border-right {
    border-right: solid 1px gray;
}

.basketball-label {
    color: #434343;
}

.basketbal-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.basketbal-container .active {
    background: var(--adm-color-primary) !important;
    color: white;
}

.basketball-team-vs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.width-100 {
    width: 100px;
}

.basketball-campaign {
    background-color: #FFFFFF;
    padding-top: 8px;
    padding-bottom: 8px;
}

.box-border {
    border: 1px solid var(--border);
}

.box-border-side {
    border-left: 1px solid var(--border);
    border-right: 1px solid var(--border);
}

.basketball-item-info .half:first-child {
    border-right: 1px dashed var(--border);
}

.half {
    width: 50%;
    background-color: #FFFFFF;
    padding-top: 8px;
    padding-bottom: 8px;
    margin: 0 auto;
    text-align: center;
}

.team-name {
    margin-bottom: 15px;
    text-align: center;
}

.mix-label {
    max-width: 12px;
    min-width: 12px;
    padding: 0 3px;
    font-size: 10px;
    text-align: center;
}

.mix-op {
    width: 50px;
    height: 74px;
    padding: 10px;
    font-size: 10px;
    text-align: center;
    border-top: 1px solid var(--border);
    border-right: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
    border-left: 0px;
}

.basketball-container .mix-team .mix-item:nth-child(2n+1) .mix-op {
    border-bottom: 0px;
}

.basketball-container .mix-team .mix-item:nth-child(2n+1) .mix-label {
    background-color: #f0f0f0;
    color: #434343;
}

.basketball-container .mix-team .mix-item:nth-child(1) .basketball-item-info {
    border-top: 1px solid var(--border);
    border-right: 1px solid var(--border);
    border-left: 1px solid var(--border);
    border-bottom: 0px;
}

.basketball-container .mix-team .mix-item:nth-child(2) .mix-label {
    background-color: var(--adm-color-primary);
    color: white;
}

.basketball-win-delta .basketball-item-info {
    height: 35px;
    line-height: 35px;
}

.football-container .active {
    background: var(--adm-color-primary) !important;
    color: white;
}


.basketball-container .item_body {
    padding: 21px 9px 21px 9px;
    border-bottom: 1px solid #eaeaea;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.basketball-container .item_body .left {
    font-size: 16px;
    width: 20%;
    text-align: center;
    color: #999;
}
.basketball-container .item_body .left p {
    margin-top: 3px;
}
.basketball-container .item_body .right {
    width: 80%;
    display: flex;
    flex-direction: column;
}
.basketball-container .item_body .right .team {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    margin-bottom: 13px;
    padding: 0 5px;
}
.basketball-container table {
    margin-top: 8px;
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    font-size: 14px;
}

.basketball-container table td {
    border: 1px solid #ccc;
    height: 40px;
}

.basketball-container table td .c_999 {
    font-size: 14px;
    color: #999;
}

.basketball-container .green_score {
    color: #fc4b27;
}

.basketball-container .purple_score {
    color: #3ab7a0;
}

.basketball-container .active23 {
    color: #303133!important;
    background-color: #fbf5f6!important;
}

.basketball-container .flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.basketball-container .flex-col {
    flex-direction: column;
}

.basketball-container .jz_item_mark {
    position: absolute;
    left: 0;
    top: 0;
    background: #fc4b27;
    font-size: 13px;
    color: #fff;
    padding: 1px 3px;
}

.basketball-container .adm-mask-content {
    height: 100%;
}

.basketball-container .adm-tabs-content {
    padding: 0;
}

.basketball-container .popView {
    background-color: #eaeaea;
    height: 100%;
    padding: 8px;
    color: #303133;
    border-radius: 5px;
    overflow: hidden;
}

.basketball-container .popView .jz_game_team {
    background-color: #fff;
    width: 100%;
    height: 38px;
    display: flex;
    font-size: 16px;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
}
.basketball-container .popView .bg_fff {
    background-color: #fff;
}
.basketball-container .popView .c_85b3d1 {
    color: #85b3d1;
}
.basketball-container .popView .min-view table {
    margin: 0!important;
}

.basketball-container .popView .game_data .c_999 {
    margin-left: 5px;
}
.basketball-container .popView .btn {
    margin-top: 16px;
    display: flex;
    justify-content: space-around;
    text-align: center;
    font-size: 16px;
}
.basketball-container .popView .bg_90ddcf {
    background-color: #90ddcf;
    color: #fff;
}
.basketball-container .popView .bg_e190cd {
    background-color: #429fff;
    color: #fff;
}

.basketball-container .popView .btn {
    margin-top: 16px;
    display: flex;
    justify-content: space-around;
    text-align: center;
    font-size: 16px;
}
.basketball-container .popView .btn .qx {
    width: 45%;
    background: #fce3de;
    color: #fc4b27;
    padding: 11px 0;
    border-radius: 5px;
}
.basketball-container .popView .btn .done {
    width: 45%;
    background: #fc4b27;
    color: #fff;
    padding: 11px 0;
    border-radius: 5px;
}

.basketball-container .active22 {
    color: #fff!important;
    background-color: #fc4b27!important;
}
.basketball-container .active22 .c_999 {
    color: #fff!important;
}

.basketball-container .active22 .purple_score,
.basketball-container .active22 .green_score {
    color: #fff!important;
}

.game_btn {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}
.jz_select_bf {
    margin-top: 12px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    font-size: 15px;
    color: #666;
    position: relative;
    border: 1px solid #999;
}