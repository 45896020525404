.follow-buy-detail .bigView {
    width: 100vw;
    min-height: 100vh;
    padding-bottom: 120px;
    font-size: 15px;
  }
  
  .follow-buy-detail .tips {
    height: 30px;
    line-height: 30px;
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: left;
    background-color: #fff9e6;
    color: #fea426;
    font-size: 13px;
    padding-left: 11px;
  }
  

  .follow-buy-detail .tips .uni-image {
    width: 19px;
    height: 19px;
    margin-right: 7px;
  }
  
  .follow-buy-detail .u-m-t-10 {
    margin-top: 5px!important;
  }

  .follow-buy-detail .container {
    background-color: #fff;
}

.follow-buy-detail .container .txt {
    padding: 0 13px;
    font-size: 13px;
    font-weight: 400;
    color: #030303;
    margin-top: -10px;
}

.follow-buy-detail .container .item {
    padding: 16px 13px 21px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.follow-buy-detail .container .item .left {
    display: flex;
    align-items: center;
}

.follow-buy-detail .container .item .left .uni-image {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;
}
.follow-buy-detail .container .item .left .uni-image img {
    width: 100%;
    height: 100%;
}
.follow-buy-detail .container .item .left .i1 {
    margin-left: 11px;
}

.follow-buy-detail .container .item .left .i1 .hot-level {
    font-size: 10px;
    padding: 1px 8px;
    border: 1px solid transparent;
    border-radius: 10px;
    margin-left: 12px;
    flex-shrink: 0;
}

.follow-buy-detail .container .item .left .i1 .hot-level.yellow {
    color: #f2a218;
    border-color: #f2a218;
}

.follow-buy-detail .container .item .right .gdyj {
    width: 106px;
    height: 46px;
    background: #fc4b27;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}


.follow-buy-detail .container .txt {
    padding: 0 13px;
    font-size: 13px;
    font-weight: 400;
    color: #030303;
    margin-top: -10px;
}

.follow-buy-detail .container .bottom {
    margin-top: 16px;
    padding: 0 13px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}
.follow-buy-detail .container .bottom .r1 {
    font-weight: 900;
    font-size: 17px;
}
.follow-buy-detail .detail {
    margin-top: 6px;
    background-color: #fff;
}
.follow-buy-detail .gdList {
    width: 100%;
    margin-top: 6px;
    background-color: #fff;
    padding: 0 11px 50px;
    box-sizing: border-box;
}
.follow-buy-detail .gdList .gd_title {
    height: 46px;
    line-height: 46px;
}
.follow-buy-detail .gdList .gd_title span:nth-child(1) {
    font-size: 15px;
    font-weight: 600;
    color: #030303;
}
.follow-buy-detail .gdList .gd_title span:nth-child(2) {
    font-size: 13px;
    font-weight: 400;
    color: #666;
}
.follow-buy-detail .gdList table {
    width: 100%;
    text-align: center;
    font-size: 13px;
}
.follow-buy-detail .gdList table th {
    background-color: #dfdfdf;
    border-radius: 2px 0 0 0;
    color: #666;
    height: 35px;
}
.follow-buy-detail .gdList table td {
    background-color: #f6f6f6;
    color: #030303;
    height: 35px;
}
.follow-buy-detail .footer {
    z-index: 999;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9999999;
}
.follow-buy-detail .footer .sureBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
}
.follow-buy-detail .footer .sureBtn .left {
    height: 53px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 19px 0 13px;
    width: 65%;
    background: #fff;
    font-size: 15px;
    font-weight: 600;
}

.follow-buy-detail .footer .sureBtn .left .mul {
    margin: 0 6px;
    width: 78px;
    height: 32px;
    line-height: 32px;
    font-size: 17px;
    text-align: left;
    color: #fc4b27;
    border: 1px solid #fc4b27;
    border-radius: 4px;
    text-align: center;
}
.follow-buy-detail .fee {
    color: #ff3737;
}
.follow-buy-detail .footer .sureBtn .right {
    height: 53px;
    line-height: 53px;
    width: 35%;
    text-align: center;
    background: #fc4b27;
    color: #fff;
    font-size: 17px;
}