.com {
    position: relative;
    width: 100vw;
    flex: 1 1;
}

.com .dp {
    position: absolute;
    top: 7%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    height: 43px;
    line-height: 43px;
    padding: 0 8px;
    border-radius: 21px;
    white-space: nowrap;
    text-align: center;
    border: 1px solid #fff;
}

.com .dp .dp_txt {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    margin: 0 6px;
}

.com .dhzo{
    position: absolute;
    top: 67%;
    left: 50%;
    transform: translate(-50%,0%);
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.com .dhzo .map {
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 4px
}