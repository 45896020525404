
.login-input {
    width: 100%;
    height: 50px;
    box-shadow: #7f7f7f7f 0 1px 6px;
    border-radius: 6px;
    padding: 12px 9px 12px 40px;
    margin: 9px 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.btn-main {
    border-radius: 2em;
    padding: 18px 76px;
    font-weight: bold;
}