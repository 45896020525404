.agent-my-statistics .container .top-tips {
    background-color: #fbf5f6;
    color: #fc4b27;
    padding: 8px;
    text-align: center;
    font-size: 14px;
}

.agent-my-statistics .container .item {
    background-color: #fff;
    padding: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eaeaea;
}

.agent-my-statistics .container .item .left {
    font-weight: 700;
}

.agent-my-statistics .container .item .right {
    text-align: right;
}

.agent-my-statistics .container .item .right .fr .pice {
    color: #fc4b27;
}