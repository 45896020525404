.person-center .personalInfoContainer {
    display: flex;
    flex-direction: row;
    padding: 20px;
    background: linear-gradient(to bottom, var(--adm-color-primary), #ffbb96);
    height: 120px;
}

.person-center .personalInfoToolItem {
    color: #434343;
}

.person-center .avatar {
    flex: 0
}

.person-center .name {
    flex: 1;
    height: 50px;
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
    margin-left: 8px;
}

.person-center .log-out {
    padding-left: 30px;
    font-size: 14px;
    color: var(--adm-color-primary);
}

.person-center .log-out svg {
    margin-right: 4px;
}

.person-center .person-icon {
    width: 40px;
}

.person-center .person-icon svg {
    width: 18px;
    height: 18px;
}

.person-center .order-history {
    background-color: #FFFFFF;
    border-radius: 8px;
    margin: 0px 20px 10px 20px;
    padding: 16px;
    z-index: 1;
}

.person-center .personal-label {
    font-size: 16px;
    font-weight: 600;
}

.person-center .personal-tips {
    font-size: 12px;
    color: var(--color);
}

.person-center .personal-highlight {
    font-size: 24px;
    font-weight: 600;
    color: var(--adm-color-primary);
}

.person-center .person-ps {
    font-size: 10px;
    color: var(--color);
    margin-top: 2px;
}

.person-center .personal-tool {
    width: 20px;
    height: 20px;
}

.person-center .personal-tools-icon  {
    width: 36px;
    height: 36px;
}

