@font-face {
  font-family: 'number-1';
  src: url(./assets/font/Aberforth_Tiles.ttf);
}

@-webkit-keyframes move {
  0% {
      background-position: 0 0;
  }

  100% {
      background-position: 40px 0;
  }
}

* {
  margin: 0;
  padding: 0;
  border: 0;
}

div {
  font-size: 15px;
  box-sizing: border-box;
  user-select: none;
}

a {
  text-decoration: none;
  color: #000;
}

input,textarea {
  outline: none;
}

.adm-nav-bar {
  background-color: #fc4b27;
  color: white;
}

.adm-tabs-header {
  background: white;
}

.adm-nav-bar-back-arrow {
  font-size: 24px;
}

.adm-dropdown-item-highlight {
  color: white;
}


.aui-flexView {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.aui-navBar {
  height: 50px;
  line-height: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #ff4800;
}

.aui-navBar-item-left {
  left: 0;
  position: absolute;
}

.aui-navBar-item-right {
  color: #ffffff;
  right: 10px;
  position: absolute;
}

.aui-scrollView {
  width: 100%;
  height: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  position: relative;
  /* margin-bottom: 44px; */
}

.aui-center-title {
  text-align: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  font-size: 1rem;
  color: #ffffff;
}

.aui-center {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  width: 50%;
  margin-left: 25%;
}

.nav-list-normal {
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #cfcfcf;
  background: #ffffff;
  overflow: auto;
}
.nav-list-normal::-webkit-scrollbar { width: 0 !important }
.nav-list-normal ul {
  width: max-content;
}

.nav-list-normal ul li {
  width: 120px;
  display: inline-block;
  text-align: center;
}

.nav-this-normal {
  color: #ff4800;
  font-weight: bold;
}

.nav-list-radis {
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  background: #f3f3f3;
  overflow: hidden;
}

.nav-list-radis ul {
  width: 100%;
}

.nav-list-radis ul li {
  display: inline-block;
  text-align: center;
  border-radius: 2em;
  background: #ffffff;
  height: 40px;
  line-height: 40px;
  margin: 10px 6px;
}

.nav-this-radis {
  border: 1px solid #ff4800;
  color: #ff4800;
}


.grid-6-6 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid-3-3-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-2-10 {
  display: grid;
  grid-template-columns: 1fr 5fr;
}

.grid-10-2 {
  display: grid;
  grid-template-columns: 5fr 1fr;
}

.grid-3-9 {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.grid-9-3 {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.grid-3-9_2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr;
}

.grid-3-3-3-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.font-orange {
  color: #ff4800;
}

.btn-aw {
  width: 100%;
  padding: 12px 0;
  border-radius: 6px;
}

.btn-orange {
  color: #ffffff;
  background-color: #ff4800;
  text-align: center;
}
.btn-orange-1 {
  font-size: 14px;
  color: #ff4800;
  border: 1px solid #ff4800;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 3px 6px;
}

.btn-orange:hover {
  opacity: .75;
}

.table-2 {
  font-size: 12px;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  table-layout: fixed;
}

.table-2 * {
  padding: 6px 0;
}

.table-2 th {
  border: 1px solid #cfcfcf;
  background-color: #f3f3f3;
  color: #5f5f5f;
}

.table-2 td {
  border: 1px solid #cfcfcf;
  overflow: auto;
}

.bg-gray {
  background: #f3f3f3 !important;
}


.block-white {
  background-color: #ffffff;
  padding: 0 14px;
  margin-bottom: 12px;
}

.block-white>* {
  padding: 14px 0;
}

.border-chose {
  border: 1px solid #9f9f9f;
  color: #9f9f9f;
  display: inline-block;
  padding: 3px 6px;
  text-align: center;
}
.bordercl-orange {
  border-color: #ff4800;
  color: #ff4800;
}

.choose-span span {
  font-size: 12px;
  text-align: center;
  margin-right: 4px;
  padding: 6px 4px;
  border-radius: 3px;
  background: #f3f3f3;
  color: #afafaf;
}

.choose-span-1 span {
  font-size: 18px;
  text-align: center;
  padding: 6px 4px;
  border-radius: 3px;
  background: #ffffff;
  color: #000000;
}

.check-orange {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #cfcfcf;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.check-orange:checked {
  border-radius: 50%;
  background: url(./assets/images/checked.jpg) #ff4800 no-repeat center center;
  background-size: contain;
  color: #ffffff;
  text-align: center;
  font-weight: bolder;
}

.text-area {
  background-color: #f3f3f3;
  height: 60px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}



.label-2 {
  color: #3f3f3f;
}

.icon-u {
  background: url(./assets/images/icon-d.png) no-repeat center center;
  background-size: 18px 18px;
  width: 18px;
  height: 18px;
  margin: 2px;
  float: left;
  transform: rotate(180deg);
  transition: linear .5s;
}

.font-money {
  color: #ff0000;
}

.layer-shade {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background: #000000af;
  z-index: 100;
}
.layer-block {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #f3f3f3;
  z-index: 1;
}
.layer-block>div {
  margin: 12px 18px;
}
.keyboard {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  text-align: center;
}
.keyboard span {
  font-size: 20px;
  font-weight: 500;
  border: 1px solid #f3f3f3;
  background: #ffffff;
  height: 55px;
  line-height: 55px;
}
.keyboard span:active {
  background: #8f8f8f!important;
  color: #ffffff;
  transition: ease-in-out 0.25s;
}
.key-del, .key-cur {
  background: #afafaf!important;
}
.key-del img {
  width: 18px;
}

.bottom-bar-next {
  width: 100vw;
  height: 55px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999999!important;
}

.bottom-bar-next .bottom-bar-foot {
  border-top: 1px solid #eaeaea;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  height: 55px;
  line-height: 55px;
  background-color: #fce3de;
  text-align: center;
}

.bottom-bar-next .bottom-bar-foot .bottom-bar-foot-left {
  min-width: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.plrxj {
  display: inline-block;
  border: 1px solid #fc4b27;
  color: #fc4b27;
  height: 27px;
  width: 59px;
  line-height: 25px;
  margin-left: 9px!important;
  border-radius: 13px;
}

.bottom-bar-next .bottom-bar-foot .bottom-bar-foot-next {
  width: 30vw;
  font-size: 19px;
  line-height: 55px;
  background-color: #fc4b27;
  color: #fff;
}

.u-m-l-20 {
  margin-left: 11px!important;
}

.u-m-r-10 {
  margin-right: 5px!important;
}
.u-m-t-20 {
  margin-top: 10px!important;
}
.u-p-t-10 {
  padding-top: 5px!important;
}

.order-info-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 10px 0;
  justify-content: space-around;
  align-items: center;
  background-color: #fff7e6;
  color: #873800;
}

.adm-tab-bar-wrap {
  overflow: visible;
}